import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Alert from "~components/alert"
import T from "~components/translator"
import mailto from "~components/mailto"
import Awesome from "~components/awesome"

import FrontmatterPageContext from "~context/FakePageContext"
// import { secondary_card } from "~vanilla/guide-primary.css"
import DisplayGuidesInPrimary, { primary_orphans_to_cards } from "~components/guides_in_primary"

import { BreadcrumbsPrimary } from "~components/Breadcrumbs/Breadcrumbs"

// @TODO extract this out of gatsby-node for reuse other places like here
// import { mdPathToPageOptions } from "../../shared_code"

import FeedbackFormKeep from "~components/feedback-formkeep"

import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import LayoutCore from "./layout-core"
import { main, mainArea } from "~vanilla/layout-default.css"
import { sideMenu } from "~components/SideMenu/side-menu.css"

import { SideMenuSwitcher } from "~components/SideMenu/side-menu"
import { TranslationJA } from "~components/includes"

const LayoutProductPrimary = ({ pageContext, location, data } ) => {
  const { frontmatter, body } = pageContext
  frontmatter['section_safe'] = frontmatter['section'] || 'none'

  // for MDXProvider later
  let mdx_components = { T, Alert, mailto, Awesome }

  // this was a old product page thing?
  let conditionalFeedback = (<></>)
  if ('feedback' in frontmatter && frontmatter['feedback'] === true) {
    conditionalFeedback = (<FeedbackFormKeep id="7a402cfe248d" pathname={location.pathname} />)
  }

  let sec_cards = []

  let primary_orphans = primary_orphans_to_cards(frontmatter, data.primary_orphans);


  return (
    <FrontmatterPageContext.Provider value={frontmatter}>

    <LayoutCore>
      <div className={`${mainArea} grid-main`}>

      <div className={`${sideMenu} main-nav section-${frontmatter.section_safe}`} role="navigation" aria-label="Navigation between documents" data-swiftype-index="false">
        <SideMenuSwitcher />
      </div>

      <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
        <div className="content-crumb">
          <BreadcrumbsPrimary />
        </div>

        <div className="content-lang">
        </div>

        <div className="content-content">
          <MDXProvider components={mdx_components}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>

          <DisplayGuidesInPrimary
            rootPage="true"
            frontmatter={frontmatter}
            body={<MDXProvider components={mdx_components}>
                    <MDXRenderer>{body}</MDXRenderer>
                  </MDXProvider>}
            sec_cards={sec_cards}
            primary_orphans={primary_orphans}
          />

          {frontmatter?.lang === "ja" && <TranslationJA />}

          {conditionalFeedback}
        </div>

      </main>
      </div>
    </LayoutCore>
    </FrontmatterPageContext.Provider>
  )
}

export default LayoutProductPrimary

export const query = graphql`
query ($primary_nav: String, $re_src_collection: String) {
  guides_in_primary: allMdx(
    filter: {
      frontmatter: {
        primary_nav: {eq: $primary_nav}
        unlisted: {ne: true}
        },
      fileAbsolutePath: {regex: $re_src_collection}

    }
  ) {
    group(field: frontmatter___secondary_nav) {
      nodes {
        fileAbsolutePath
        slug
        frontmatter {
          primary_nav
          secondary_nav
          header
          unlisted
        }
      }
    }
  }
  primary_orphans: allMdx(
    filter: {
      frontmatter: {
        primary_nav: {eq: $primary_nav},
        unlisted: {ne: true}
      },
      fileAbsolutePath: {regex: $re_src_collection}
    }
  ) {
    nodes {
      fileAbsolutePath
      slug
      excerpt
      frontmatter {
        primary_nav
        secondary_nav
        header
        unlisted
      }
    }
  }
}
`


export const Head = (props) => {
  const { frontmatter } = props.pageContext
  const { pathname } = props.location

  const full_title = (
      <>{frontmatter["section_name"]} | Fastly Documentation</>
  )

  let langlinks = ""
  if (frontmatter?.i18n) {
    langlinks = []
    langlinks.push (<link rel="alternate" hrefLang={frontmatter["lang"]} href={props.location.pathname} data-self="1" />)

    let re = new RegExp(`^/${frontmatter.lang}/`)
    Object.values(frontmatter.i18n).forEach((langkey) => {
      let langpath = pathname.replace(re, `/${langkey}/`)

      // @TODO: this needs to be a full absolute path, need to query sitemetadata etc
      langlinks.push(<link rel="alternate" hrefLang={langkey} href={langpath} />)
    })
  }

  const noindex = (frontmatter?.sitemap == false || frontmatter?.unlisted == true) ?
      (frontmatter?.forceindex) ? <></> : <meta name="robots" content="noindex" />
    : <></>

  return (
    <>
      <title>{full_title}</title>
      {langlinks}
      {noindex}
      <SeoHead {...props} />
      <FavHead />
    </>
  )
}
